<template>
  <div v-intersection-observer="onIntersectionObserver" class="category-list-item-container">
    <div
      class="category-list-item-container"
      :class="{ 'darker-background-color': isCategoryAlreadySelected }"
      @click="$emit('select', category.id)"
    >
      <div class="category-icon-wrapper">
        <template v-if="isOrWasOnScreen && categoryIconMetadata">
          <uds-squircle-icon
            size="small"
            :icon="categoryIconMetadata.icon"
            :color="categoryIconMetadata.color"
          />
        </template>
      </div>
      <div class="category-name-and-tag">
        <CommonsTextTooltipIfTextOverflowed
          style="width: 100%"
          :text="categoryName"
          class="category-name"
        />
        <template v-if="category.matchedTag">
          <div class="category-tag">{{ category.matchedTag }}</div>
        </template>
      </div>
      <template v-if="tooltip">
        <div class="category-tooltip-wrapper">
          <template v-if="isOrWasOnScreen">
            <uds-tooltip :tooltip-text="tooltip">
              <uds-icon icon-name="info" color="#DDDDDD" />
            </uds-tooltip>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IAccount} from "@silexpert/core";
import { AccountType } from "@silexpert/core";
import { vIntersectionObserver } from "@vueuse/components";

defineEmits(["close", "select"]);

const props = withDefaults(
  defineProps<{
    category: IAccount & { matchedTag: string | null };
    isCategoryAlreadySelected: boolean;
    forceAccountNumberDisplay?: boolean;
  }>(),
  {
    forceAccountNumberDisplay: false,
  },
);

const userStore = useUsersStore();
const primaryColors = computed(() => getPrimaryColors());

const tooltip = computed<string | null>(() => props.category.accountHelp?.comment ?? null);

// used to avoid loading immediately too many components when the dropdown is opened (it can be very slow)
const isOrWasOnScreen = ref<boolean>(false);

function onIntersectionObserver([{ isIntersecting }]: IntersectionObserverEntry[]) {
  isOrWasOnScreen.value = isIntersecting;
}

const hasAccountNumberDisplay = computed<boolean>(
  () => props.forceAccountNumberDisplay || (userStore.user?.hasAccountNumberDisplay ?? false),
);

const categoryName = computed<string>(() => {
  if (hasAccountNumberDisplay.value) {
    return `${props.category?.number ?? ""} ${props.category.name}`;
  } else {
    return props.category.name;
  }
});
const categoryIconMetadata = computed<{ id: number; color: string; icon: string } | null>(() => {
  if (!props.category) {
    return {
      color: primaryColors.value.primary500,
      icon: "undefined",
      id: 0,
    };
  }

  const categories = Object.values(AccountType);

  return (
    categories.find((c) => c.id === props.category.accountType?.id) ?? {
      color: primaryColors.value.primary500,
      icon: "undefined",
      id: 0,
    }
  );
});
</script>

<style lang="scss" scoped>
.category-list-item-container {
  height: auto;
  min-height: 40px;
  width: 100%;
  margin: 3px 0;

  display: flex;
  align-items: center;

  &:hover {
    background-color: $uds-neutral-100;
    cursor: pointer;
  }

  &.darker-background-color {
    background-color: $uds-neutral-100;
  }

  .category-icon-wrapper {
    margin-left: 14px;
    margin-right: 14px;

    width: 27px;
    height: 27px;
  }

  .category-name-and-tag {
    flex-grow: 1;
    width: calc(400px - 68px);

    .category-name {
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-900;
    }

    .category-tag {
      font-size: 14px;
      line-height: 18px;
      color: $uds-primary-500;
      background-color: $uds-primary-50;
      border-radius: 3px;

      display: inline-block;
      padding: 0px 10px 0px 10px;
    }
  }

  .category-tooltip-wrapper {
    margin-left: 14px;
    margin-right: 14px;

    width: $uds-spacing-3;
    height: $uds-spacing-3;
  }
}
</style>
