<template>
  <div
    class="partner-list-container"
    :class="{ 'much-item scrollbar': partners.length > 3 }"
    @click="$emit('select')"
  >
    <div
      v-for="partner in partners"
      :key="partner.id"
      class="partner-item"
      @click="$emit('selectPartner', partner)"
    >
      <div class="partner-icon">
        <uds-icon v-if="partnerInfo(partner).isCompany" icon-name="apartment" color="#DDDDDD" />
        <uds-icon v-else icon-name="person" />
      </div>
      <span class="partner-name">
        <CommonsTextTooltipIfTextOverflowed :text="partnerInfo(partner).name" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ISocietyPartner } from "@silexpert/core";

const partnerStore = usePartnerStore();
defineEmits(["select", "selectPartner"]);

const partners = computed<ISocietyPartner[]>(() =>
  (partnerStore.partners ?? []).filter((p) => !isDefined(p.deletedAt)),
);

function partnerInfo(partner: ISocietyPartner): { name: string; isCompany: boolean } {
  if (partner.firstname || partner.lastname) {
    return { name: `${partner.firstname} ${partner.lastname}`, isCompany: false };
  } else {
    return { name: partner.company!, isCompany: true };
  }
}
</script>

<style lang="scss" scoped>
.partner-list-container {
  display: flex;
  flex-direction: column;

  align-items: flex-end;
  background: $uds-neutral-100;

  padding-right: $uds-spacing-3;
  height: auto;

  &.much-item {
    max-height: 155px;
  }

  .partner-item {
    display: flex;
    height: 48px;
    width: 312px;
    padding: $uds-spacing-1_5;

    border-radius: $uds-radius-2;
    background: $uds-white;
    margin-bottom: $uds-spacing-1;
    align-items: center;

    :deep(.material-symbols-rounded) {
      margin-right: $uds-spacing-1_5;
      font-size: 18px;
      color: $uds-neutral-600;
    }

    .partner-name {
      font-weight: 100;
      max-width: 260px;
      font-size: 16px;
    }

    &:hover {
      cursor: pointer;
      background: $uds-primary-50;
      :deep(.material-symbols-rounded) {
        color: $uds-primary-500;
      }
    }
  }
}
</style>
