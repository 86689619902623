<template>
  <div class="category-dropdown-container">
    <div v-if="hasIsAsset" class="category-dropdown-switch">
      <div class="cd-switch-line">
        <uds-switch
          label="Avoir ou remboursement"
          :value="isAsset"
          @change="$emit('select', !isAsset)"
        />
        <uds-tooltip tooltip-text="Choisir la catégorie correspondante au paiement initial.">
          <uds-icon icon-name="info" color="#DDDDDD" />
        </uds-tooltip>
      </div>

      <uds-switch
        v-if="isComptalib() && isFromBank"
        :value="isLoansCategories"
        label="Emprunt"
        @change="isLoansCategories = !isLoansCategories"
      />
    </div>
    <div class="category-dropdown-search">
      <CommonsDropdownsDropdownSearch v-model:data="search" />
    </div>
    <div class="category-dropdown-list">
      <CommonsSuggestions v-if="canDisplayMLSuggest" class="--padding">
        <div
          v-for="mlItem in mlResultFormated"
          :key="mlItem.id"
          class="ml-category-list-container uds-shadow-s"
          @click="$emit('select', mlItem.idAccount)"
        >
          <div class="ml-icon">
            <uds-squircle-icon
              :icon="getMlCategoryIcon(mlItem)?.icon"
              :color="getMlCategoryIcon(mlItem)?.color"
              size="xsmall"
            />
          </div>
          <span class="ml-name">{{ mlItem.account.name }}</span>
        </div>
      </CommonsSuggestions>
      <template v-if="hasPartners && displayPartner">
        <div
          class="category-list-item-container"
          :class="{ 'darker-background-color': openDropdown }"
          @click="openDropdown = !openDropdown"
        >
          <div class="category-icon-wrapper">
            <uds-squircle-icon icon="Others" size="small" />
          </div>

          <div class="category-name-and-tag">
            <CommonsTextTooltipIfTextOverflowed
              style="width: 100%"
              text="Apport ou remb. d'une personne"
              class="category-name"
            />
          </div>
          <div class="category-collapse">
            <uds-icon v-if="openDropdown" icon-name="keyboard_arrow_up" color="#DDDDDD" />
            <uds-icon v-else icon-name="keyboard_arrow_down" color="#DDDDDD" />
          </div>

          <div class="category-tooltip-wrapper">
            <uds-tooltip :tooltip-text="partnersTooltipText">
              <uds-icon icon-name="info" color="#DDDDDD" />
            </uds-tooltip>
          </div>
        </div>

        <div class="category-associate-list" :class="{ show: openDropdown }">
          <CommonsDropdownsCategoryDropdownAssociatedItem @select-partner="selectPartner" />
          <div class="display-more">
            <slot name="partnermodal" />
          </div>
        </div>
      </template>
      <div v-for="(category, categoryIndex) in associatedCategories" :key="categoryIndex">
        <CommonsDropdownsCategoryDropdownItemLine
          :category="category"
          :force-account-number-display="forceAccountNumberDisplay"
          :is-category-already-selected="transaction.idAccount === category.id"
          @select="$emit('select', category.id)"
        />
      </div>
    </div>
    <slot name="modal" />
  </div>
</template>

<script setup lang="ts">
import type {
  IMachineLearningResult,
  IAccount,
  IAccountType,
  ISocietyPartner,
} from "@silexpert/core";
import { AccountType } from "@silexpert/core";
import type { FuseResult } from "fuse.js";
import Fuse from "fuse.js";

type CategoryWithMatchedTag = IAccount & {
  // if a category has been matched using a tag, the tag will appear here
  matchedTag: string | null;
};

type MLCategory = IMachineLearningResult & {
  typeAccount: IAccountType;
};

const partnerStore = usePartnerStore();
const categoryStore = useCategoryStore();
const route = useRoute();
const { getCategoriesForAnnotating } = useAnnotationUtilsComposable();
const { isComptalib } = useBrandsComposable();
const primaryColors = computed(() => getPrimaryColors());

const emit = defineEmits<{
  (e: "select", id: number | boolean | undefined): void;
}>();

const props = withDefaults(
  defineProps<{
    hasCreditCategories: boolean;
    hasNeutralCategories: boolean;
    hasDebitCategories: boolean;
    hasIsAsset?: boolean;
    isAsset: boolean;
    transaction?: { amount: number; date: string; label: string; idAccount: number | null };
    machineLearningResults?: IMachineLearningResult[];
    forceAccountNumberDisplay?: boolean;
    displayPartner?: boolean;
  }>(),
  {
    transaction() {
      return { amount: 0, date: "", label: "", idAccount: null };
    },
    machineLearningResults() {
      return [];
    },
    forceAccountNumberDisplay: false,
    displayPartner: false,
    hasIsAsset: true,
  },
);

const search = ref<string | null>(null);
const isLoansCategories = ref<boolean>(false);

const isFromBank = computed<boolean>(() => route.name === "banks");

const canDisplayMLSuggest = computed<boolean>(() => {
  return (
    props.machineLearningResults.length > 0 && (search.value?.length === 0 || search.value === null)
  );
});

const associatedCategories = computed<CategoryWithMatchedTag[]>(() => {
  const partenerAccountId = (partnerStore.partners ?? []).map((pa) => pa.idAccount);
  const filteredCat = categoriesToList.value.filter((cat) => !partenerAccountId.includes(cat.id!));
  return filteredCat;
});

const categoriesThatCanAnnotateThisTransaction = computed<CategoryWithMatchedTag[]>(() => {
  const filteredCategoriesForAnnotating = getCategoriesForAnnotating(
    {
      credit: props.hasCreditCategories,
      neutral: props.hasNeutralCategories,
      debit: props.hasDebitCategories,
    },
    isLoansCategories.value,
  );

  const categoriesForAnnotatingWithDefaultMatchedTag = filteredCategoriesForAnnotating.map((c) => {
    return {
      ...c,
      matchedTag: null,
    };
  });
  return categoriesForAnnotatingWithDefaultMatchedTag;
});

const categoriesToList = computed<CategoryWithMatchedTag[]>(() => {
  const categoriesMatchedByTag = matchCategoriesByTag(
    categoriesThatCanAnnotateThisTransaction.value,
    search.value ?? "",
  );

  const categoriesMatchedByName = matchCategoriesByName(
    categoriesThatCanAnnotateThisTransaction.value,
    search.value ?? "",
  );

  // remove categories of categoriesMatchedByName that are already present in categoriesMatchedByTag
  const categoriesMatchedByTagIds = categoriesMatchedByTag.map((c) => c.id);
  const categoriesMatchedByNameNotAlreadyMatchedByTag = categoriesMatchedByName.filter((c) => {
    return !categoriesMatchedByTagIds.includes(c.id);
  });

  const categoriesToList = [
    ...categoriesMatchedByTag,
    ...categoriesMatchedByNameNotAlreadyMatchedByTag,
  ];

  return categoriesToList;
});

const mlResultFormated = computed<MLCategory[]>(() => {
  let mlCategories: MLCategory[] = [];
  for (let index = 0; index < props.machineLearningResults.length; index++) {
    const mlItem = props.machineLearningResults[index];
    const categoryId = mlItem?.idAccount ?? null;

    const categoriesList = isLoansCategories.value
      ? categoryStore.loanCategories
      : categoryStore.categories;

    const typeAccount = categoriesList.find((category: IAccount) => category.id === categoryId)
      ?.accountType as IAccountType;

    mlCategories.push({ typeAccount, ...mlItem });
  }

  mlCategories = mlCategories.sort((a, b) => {
    return b.scoring - a.scoring;
  });

  if (mlCategories.length > 3) {
    mlCategories.length = 3;
  }

  return mlCategories.filter(
    (value, index, self) => index === self.findIndex((c) => c.account.id === value.account.id),
  );
});

function matchCategoriesByTag(categories: IAccount[], search: string): CategoryWithMatchedTag[] {
  if (search === "") {
    return [];
  }

  // put the tags in a single simple array property so Fuse can match on it
  const categoriesWithTagNameArray = categories.map((c) => {
    return {
      ...c,
      // @ts-expect-error
      tagNames: c.tags?.map((tag) => tag.name),
    };
  });

  const fuseOptions = {
    shouldSort: true,
    includeMatches: true,
    tokenize: true,
    matchAllTokens: true,
    findAllMatches: false,
    threshold: 0,
    location: 0,
    distance: 0,
    maxPatternLength: 40,
    minMatchCharLength: 1,
    keys: ["tagNames"],
  };
  const fuseByTag = new Fuse(categoriesWithTagNameArray, fuseOptions);
  const categoriesMatchedByTag = fuseByTag.search(search) as FuseResult<IAccount>[];

  return categoriesMatchedByTag.map((fuseResult) => {
    const matchedTag = (fuseResult.matches?.[0]?.value as string) ?? null;
    return {
      ...fuseResult.item,
      matchedTag,
    };
  });
}

function matchCategoriesByName(categories: IAccount[], search: string): CategoryWithMatchedTag[] {
  if (search === "") {
    return categoriesThatCanAnnotateThisTransaction.value;
  }

  const fuseOptions = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 40,
    minMatchCharLength: 1,
    keys: ["name", "number"],
  };

  const fuseByName = new Fuse(categories, fuseOptions);

  const categoriesMatchedByName = fuseByName.search(search);
  return categoriesMatchedByName.map((fuseResult) => ({
    ...fuseResult.item,
    matchedTag: null,
  }));
}

function getMlCategoryIcon(mlItem: MLCategory) {
  if (!mlItem.typeAccount) {
    return {
      color: primaryColors.value.primary500,
      icon: "undefined",
    };
  }

  const accountsType = Object.values(AccountType);

  return (
    accountsType.find((c) => c.id === mlItem.typeAccount?.id) ?? {
      color: primaryColors.value.primary500,
      icon: "undefined",
    }
  );
}

// partners
const openDropdown = ref<boolean>(false);
const hasPartners = computed<boolean>(() => !!(partnerStore.partners ?? [])?.length);
const partnersTooltipText: string = `<p><strong>Description</strong> :</p><p> Il s'agit du compte à utiliser lors des flux de trésorerie entre la société et un associé
    Vous devez impérativement nous fournir un tableau récapitulatif mensuel ainsi que les factures correspondantes de vos notes de frais.</p>
    <p><strong>Exemples</strong> :</p><p> Remboursement de notes de frais, apport d'un associé, remboursement d'apport d'un associé.. </p>`;

function selectPartner(partner: ISocietyPartner) {
  emit("select", partner.idAccount);
}
</script>

<style lang="scss" scoped>
.category-dropdown-container {
  width: 100%;
  box-shadow: 0px 6px 16px rgba(32, 33, 36, 0.12);
  border-radius: $uds-radius-1;
  background: $uds-white;

  // on every direct child
  & > * {
    border-bottom: 1px solid $uds-neutral-500;

    &:last-child {
      border-bottom: 0px;
    }
  }

  .category-dropdown-switch {
    display: flex;
    gap: $uds-spacing-2;
    padding: $uds-spacing-1_5 $uds-spacing-2;
    color: $uds-neutral-800;
    .cd-switch-line {
      display: flex;
      align-items: center;
      gap: $uds-spacing-0_5;
    }
  }

  .category-dropdown-search {
    height: 40px;

    display: flex;
    align-items: center;
  }

  .category-dropdown-list {
    height: 200px;
    overflow-y: scroll;
  }
}

.category-associate-list {
  padding: $uds-spacing-1;
  display: none;
  overflow: hidden;

  padding: unset;
  padding-left: $uds-spacing-2;
  padding-right: $uds-spacing-2;

  &.show {
    display: block;
  }

  .category-dropdown-bottom {
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }
}

.display-more {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  border-bottom-left-radius: $uds-radius-2;
  border-bottom-right-radius: $uds-radius-2;
  border-top: 1px solid $uds-neutral-500;

  background: $uds-neutral-100;

  &:hover {
    cursor: pointer;
  }
}

// associate fake
.category-list-item-container {
  height: auto;
  min-height: 40px;
  width: auto;
  // margin-top: 3px;

  display: flex;
  align-items: center;
  margin: 0;
  // padding: 0 $uds-spacing-2 0 $uds-spacing-2;

  &:hover {
    background-color: $uds-neutral-100;
    cursor: pointer;
  }

  &.darker-background-color {
    background-color: $uds-neutral-100;
    border-top-left-radius: $uds-radius-2;
    border-top-right-radius: $uds-radius-2;
    margin: 0 $uds-spacing-2 0 $uds-spacing-2;
  }

  .category-icon-wrapper {
    margin-left: 14px;
    margin-right: 14px;

    width: 27px;
    height: 27px;
  }

  .category-name-and-tag {
    flex-grow: 1;
    width: calc(400px - 68px);

    .category-name {
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-900;
    }

    .category-tag {
      font-size: 14px;
      line-height: 18px;
      color: $uds-primary-500;
      background-color: $uds-primary-50;
      border-radius: 3px;

      display: inline-block;
      padding: 0px 10px 0px 10px;
    }
  }

  .category-tooltip-wrapper {
    margin-left: 14px;
    margin-right: 14px;

    width: $uds-spacing-3;
    height: $uds-spacing-3;
  }
}

.ml-category-list-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  max-width: 258px;
  padding: $uds-spacing-1 10px;
  margin-bottom: $uds-spacing-1;

  background: $uds-white;
  border-radius: $uds-radius-2;
  cursor: pointer;

  .ml-name {
    font-size: 16px;
    font-weight: 100;
    color: $uds-neutral-900;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
